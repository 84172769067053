export const FirebaseConfig = {
	"projectId": "runyt-f4347",
	"appId": "1:731428817870:web:eba4b480ba06224ed9f99f",
	"databaseURL": "https://runyt-f4347-default-rtdb.firebaseio.com",
	"storageBucket": "runyt-f4347.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBqoPvFJoZFdjaCq3UwSx040ip9UAK1U9I",
	"authDomain": "runyt-f4347.firebaseapp.com",
	"messagingSenderId": "731428817870",
	"measurementId": "G-50E607VTDW"
};